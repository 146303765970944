// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import * as bootstrap from 'bootstrap';

require('flatpickr');
require('flatpickr/dist/l10n/ja');
require('select2');
require('select2/dist/js/i18n/ja');

window.$ = jQuery;
window.bootstrap = bootstrap;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$.fn.select2.defaults.set('theme', 'bootstrap-5');
$.fn.select2.defaults.set('language', 'ja');

document.addEventListener('turbolinks:load', () => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (el) {
    return new bootstrap.Tooltip(el)
  })

  const toastTriggerList = [].slice.call(document.querySelectorAll('.toast'))
  toastTriggerList.map(function (el) {
    return new bootstrap.Toast(el)
  })
});

document.addEventListener('turbolinks:before-cache', function() {
  // NOTE: ブラウザバックでモーダルが表示し続け、閉じられなくなるのを回避
  $('.modal').removeClass('show').hide();
  $('.modal-backdrop').remove();
  $('body').removeClass('modal-open').removeAttr('style');

  // NOTE: ブラウザバック/フォワードでselect2表示が増え続けるため、キャッシュされないようにして回避
  $('.select2-container').remove();
});
